export class Gql {
  static getPages = (): string => {
    return `
      query GetPages{
        pages (where:{status: {equals: "published"}}){
          id
          title
          titleRu
          category
          categoryRu
          publishedAt
          status
          content{
            document
          }
          contentRu{
            document
          }
        }
      }
    `
  }
}