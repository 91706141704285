import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WhitepapperRepository } from '../api/WhitepapperRepository';
import { Page } from './types/response/GetWhitepapperResponse';

export type WhitepapperState = {
  isLoading: boolean
  error: string
  lang: 'ru' | 'en'
  pages: Page[]
  menuItems: { category: string, pages: Page[] }[]
  currentPage: {
    page: Page | null,
    index: number,
    category: string
  },
}

const initialState: WhitepapperState = {
  error: '',
  lang: 'en',
  isLoading: false,
  pages: [],
  menuItems: [],
  currentPage: {
    page: null,
    index: 0,
    category: ''
  }
};

export const whitepapperSlice = createSlice({
  name: 'whitepapper',
  initialState,

  //sync
  reducers: {
    clearState: (state) => {
      return initialState
    },

    setLang: (state, action: PayloadAction<WhitepapperState['lang']>) => {
      state.lang = action.payload;
    },

    setCurrentPage: (state, action: PayloadAction<Page>) => {
      state.currentPage.page = action.payload
      state.currentPage.index = state.pages.findIndex((item) => item.id === action.payload.id)
      state.currentPage.category = state.lang === 'en' ? action.payload.category : action.payload.categoryRu
      localStorage.setItem('currentPageId', action.payload.id)
    },

    setCurrentPageFromLocalStorage: (state) => {
      const savedPageId = localStorage.getItem('currentPageId');

      if (!savedPageId && state.pages.length > 0) {
        state.currentPage.page = state.pages[0]
      }

      if (savedPageId && state.pages.length > 0) {
        const currentPage = state.pages.find((page) => page.id === savedPageId);

        if (currentPage) {
          state.currentPage.page = currentPage
          state.currentPage.index = state.pages.findIndex((item) => item.id === currentPage.id)
          state.currentPage.category = state.lang === 'en' ? currentPage.category : currentPage.categoryRu
        }
      }
    },

    buildMenuRu: (state, action: PayloadAction<Page[]>) => {
      if (action.payload.length === 0) return;

      const categories = Array.from(new Set(action.payload.map(e => e.categoryRu))).sort((a, b) => a.localeCompare(b))

      state.menuItems = categories.map(category => {
        let pages = action.payload.filter(page => page.categoryRu == category).sort((a, b) => a.titleRu.localeCompare(b.titleRu))
        const firstPage = pages.find(page => page.titleRu === category)
        if (firstPage) {
          const index = pages.indexOf(firstPage)
          pages.unshift(firstPage)
          pages.splice(index + 1, 1)
        }
        return { category, pages }
      })

      state.pages = state.menuItems?.map(e => e.pages)?.reduce((c, v) => c.concat(v))
    },

    buildMenuEn: (state, action: PayloadAction<Page[]>) => {
      if (action.payload.length === 0) return;

      const categories = Array.from(new Set(action.payload.map(e => e.category))).sort((a, b) => a.localeCompare(b))

      state.menuItems = categories.map(category => {
        let pages = action.payload.filter(page => page.category == category).sort((a, b) => a.title.localeCompare(b.title))
        const firstPage = pages.find(page => page.title === category)
        if (firstPage) {
          const index = pages.indexOf(firstPage)
          pages.unshift(firstPage)
          pages.splice(index + 1, 1)
        }
        return { category, pages }
      })

      state.pages = state.menuItems?.map(e => e.pages)?.reduce((c, v) => c.concat(v))
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPages.fulfilled, (state, action) => {
      state.pages = action.payload;
      state.isLoading = false
      state.error = ''
    })
    builder.addCase(getPages.rejected, (state) => {
      state.isLoading = false
      state.error = 'Load documentation error'
    })
  }
});

const getPages = createAsyncThunk('docs/getPages', async () => {
  const pages = await WhitepapperRepository.getPages()
  return pages
})


export const asyncActions = { getPages }
export const actions = { ...whitepapperSlice.actions, ...asyncActions }

export default whitepapperSlice.reducer
