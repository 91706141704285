import React, { useEffect, useRef, useState } from "react";
import { actions } from '../../logic/Whitepapper'
import { useAppDispatch, useAppSelector } from '../../redux-store'

import '../../assets/styles/Whitepapper.css'

import { DocumentRenderer, DocumentRendererProps } from '@keystone-6/document-renderer';
import { Page } from "../../logic/types/response/GetWhitepapperResponse";

const renderers: DocumentRendererProps['renderers'] = {
  // use your editor's autocomplete to see what other renderers you can override
  inline: {
    bold: ({ children }) => {
      return <strong>{children}</strong>;
    },
  },
  block: {
    paragraph: ({ children, textAlign }) => {
      return <p style={{ textAlign }}>{children}</p>;
    },
    code: ({ children }) => {
      return <div className="whitep__code-container" dangerouslySetInnerHTML={{ __html: children }} />
    },
  },
};

const Whitepapper = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.whitepapper)

  useEffect(() => {
    dispatch(actions.getPages()).then(res => {
      const pages = res.payload as Page[];
      if (state.lang === 'ru') dispatch(actions.buildMenuRu(pages))
      if (state.lang === 'en') dispatch(actions.buildMenuEn(pages))
    })
  }, [])

  useEffect(() => {
    dispatch(actions.setCurrentPageFromLocalStorage())
  }, [state.pages])

  useEffect(() => {
    if (state.lang === 'ru') dispatch(actions.buildMenuRu(state.pages))
    if (state.lang === 'en') dispatch(actions.buildMenuEn(state.pages))
  }, [state.lang])
  
  const articleDomNode = useRef<HTMLElement>(null);
  
  const onClick = (page: Page) => {
    dispatch(actions.setCurrentPage(page))
    if (articleDomNode && articleDomNode.current) articleDomNode.current.scrollTop = 0;  
  }

  const [isNavOpened, setNavCondition] = useState(false)

  const openNavMenu = () => { setNavCondition(true) }
  const closeNavMenu = () => { setNavCondition(false) }

  const onClickCategory = (event: React.MouseEvent<HTMLButtonElement>, page: Page) => {
    if (page) dispatch(actions.setCurrentPage(page))
    event.currentTarget.classList.toggle('active');
  }

  const onClickToEnLang = () => {
    dispatch(actions.setLang('en'));
  }

  const onClickToRuLang = () => {
    dispatch(actions.setLang('ru'));
  }

  return (
    <div className="whitep">

      {/* левая колонка навигации */}
      <nav className={`whitep__nav ${isNavOpened ? 'active' : ''}`}>

        { /* мобильная область и кнопка скрытия навигации */}
        <div className="whitep__mobile-close" onClick={closeNavMenu}>
          <button className="whitep__close">
            <img src="img/close-mod.png" alt="" />
          </button>
        </div>

        <div className="whitep__nav-wrapper">
          <div className="whitep__nav-header">
            <h1 className="whitep__nav-header-title">
              <a href="https://inlostcity.com/" >
                <img src="img/chest-logo.png" alt="" />
              </a>
              <span>WHITEPAPPER</span>
            </h1>
            { state.lang === 'en' && (
              <button onClick={onClickToRuLang} className="whitep__nav-header-lang-btn button-lang">
                Ru
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="68px" height="49px" viewBox="0 0 68 49"><path fillRule="evenodd" strokeWidth="1px" stroke="rgb(255, 255, 255)" fillOpacity="0" fill="#FFFFFF" d="M4.376,3.967 L4.376,45.060 L54.272,45.060 L64.234,35.098 L64.234,3.967 L4.376,3.967 Z"></path></svg>
              </button>
            ) }
            { state.lang === 'ru' && (
              <button onClick={onClickToEnLang} className="whitep__nav-header-lang-btn button-lang">
                En
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="68px" height="49px" viewBox="0 0 68 49"><path fillRule="evenodd" strokeWidth="1px" stroke="rgb(255, 255, 255)" fillOpacity="0" fill="#FFFFFF" d="M4.376,3.967 L4.376,45.060 L54.272,45.060 L64.234,35.098 L64.234,3.967 L4.376,3.967 Z"></path></svg>
              </button>
            ) }
          </div>

          <ul className="whitep__nav-list">
            {state.menuItems.map((item) => {
              if (item.pages.length === 1) {
                return (
                  // Элемент навигации без вложенного списка
                  <li className="whitep__nav-item" key={item.pages[0]?.id}>
                    <button
                      className={`whitep__nav-item-btn ${state.currentPage.page === item.pages[0] ? 'active' : ''}`}
                      onClick={() => onClick(item.pages[0])}
                    >
                      {item.category}
                    </button>
                  </li>
                )
              } else {

                return (
                  // элемент навигации с вложенным списком
                  <li className="whitep__nav-item" key={item.pages[0]?.id}>
                    <button
                      className={`whitep__nav-item-btn nested ${state.currentPage.category === item.category ? 'active' : ''}`}
                      onClick={(e) => {
                        onClickCategory(e, item.pages.find(page => {
                          const title = state.lang === 'en' ? page.title : page.titleRu;
                          return title === item.category
                        })!
                        )
                      }}
                    >
                      {item.category}
                    </button>
                    <ul className="whitep__und-list">
                      {item.pages.map((page) => {
                        const title = state.lang === 'en' ? page.title : page.titleRu;
                        const category = state.lang === 'en' ? page.category : page.categoryRu;
                        if (title !== category)
                          return (
                            <li className="whitep__und-item" key={page?.id}>
                              <button
                                className={`whitep__und-btn ${state.currentPage.page === page ? 'active' : ''}`}
                                onClick={() => onClick(page)}
                              >
                                {title}
                              </button>
                            </li>
                          )
                      })
                      }
                    </ul>
                  </li>
                )
              }
            })}
          </ul>
        </div>

      </nav>

      {/* правая колонка контента */}
      <div className="whitep__content">

        {/* кнопка показа мобильной навигации */}
        <div className="whitep__show-mobile-nav-wrapper">
          <button className="whitep__show-mobile-nav" onClick={openNavMenu}>
            <img src="img/w-down.png" alt="" />
          </button>
          <span>Girand docs</span>
          <a className="whitep__show-mobile-home" href="https://inlostcity.com/">
            <img src="img/castle.png" alt="" />
          </a>
        </div>

        {/* статья */}
        <article className="whitep__article" ref={articleDomNode}>
          {(state.currentPage.page && state.lang === 'en') && <DocumentRenderer document={state.currentPage.page.content.document} renderers={renderers} />}
          {(state.currentPage.page && state.lang === 'ru') && <DocumentRenderer document={state.currentPage.page.contentRu.document} renderers={renderers} />}

          {/*кнопки след. и пред. статья*/}
          <div className="whitep__article-btns">

            <button className={`whitep__article-btn ${state.pages[state.currentPage.index - 1] ? 'active' : ''}`} onClick={() => (onClick(state.pages[state.currentPage.index - 1]))} >
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" /></svg>
              <div className="whitep__article-btn-text">
                <span>previous</span>
                <p>{
                  state.pages[state.currentPage.index - 1] ?
                    state.lang === 'en' ?
                      state.pages[state.currentPage.index - 1].title
                      : state.pages[state.currentPage.index - 1].titleRu
                    : ''}
                </p>
              </div>
            </button>

            <button className={`whitep__article-btn ${state.pages[state.currentPage.index + 1] ? 'active' : ''}`} onClick={() => (onClick(state.pages[state.currentPage.index + 1]))} >
              <div className="whitep__article-btn-text">
                <span>next</span>
                <p>{
                  state.pages[state.currentPage.index + 1] ?
                    state.lang === 'en' ?
                      state.pages[state.currentPage.index + 1].title
                      : state.pages[state.currentPage.index + 1].titleRu
                    : ''}
                </p>
              </div>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" /></svg>
            </button>
          </div>

        </article>

      </div>

    </div>
  )

}

export default Whitepapper;