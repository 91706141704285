import { GqlBody } from "../logic/types/request/RequestTypes";
import { GetWhitepapperResponse, Page } from "../logic/types/response/GetWhitepapperResponse";

import { ApiKeystoneDocs } from "./api_keystone_docs";
import { Gql } from "./api_queries";

export class WhitepapperRepository {
  private static keystone = ApiKeystoneDocs;

  // create
  static async getPages(): Promise<Page[]> {
    const query = Gql.getPages()
    const body: GqlBody = { operationName: "GetPages", variables: {}, query: query, }
    const res: GetWhitepapperResponse = await this.keystone.queryGraphql(body)
    return res.data.pages ?? []
  }
}