import React, { useEffect } from "react";
import { BrowserRouter, useLocation, useNavigate, useRoutes } from "react-router-dom";

import Whitepapper from "./ui/Whitepapper/Whitepapper";

import { useAppDispatch } from "./redux-store";
import { actions } from "./logic/Whitepapper";

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const App = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (location.pathname === '/ru') {
      dispatch(actions.setLang('ru'))
      navigate('/')
    }
    if (location.pathname === '/en') {
      dispatch(actions.setLang('en'))
      navigate('/')
    }
  }, [])

  const routes = useRoutes(
    [
      { path: "/", element: <Whitepapper /> },
    ]
  );
  return routes;
}

const AppWrapper = () => {
  return (
    <BrowserRouter >
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
