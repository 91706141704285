import axios, { AxiosRequestConfig } from "axios";
import { config } from "../config";
import { GqlBody } from "../logic/types/request/RequestTypes";

export class ApiKeystoneDocs {
  private static apiGraphql = config.apiGraphqlDocs;

  static async queryGraphql(body: GqlBody) {
    try {
      const config: AxiosRequestConfig = { headers: { "Content-Type": "application/json" }, withCredentials: true }
      const res = await axios.post(this.apiGraphql, body, config)
      console.log("queryGraphql, response success: ", res.data)
      return res.data
    } catch (err) {
      console.log('queryGraphql, response failed:', err)
    }
  }
}